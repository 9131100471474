import { Accordion, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const FAQ = () => {
    return (
        <>
            <Container fluid>
                <div className="faq-section">
                    <h2 className="h1-text text-center mb-md-20 mb-10px">FAQs</h2>
                    <Accordion >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Vad gör ert företag och vilka tjänster erbjuder ni?</Accordion.Header>
                            <Accordion.Body className="body-text">
                                <p>Filmlabbet är ett produktionbolag som producerar både film- och fotomaterial för företag. Vi erbjuder allt ifrån enstaka projekt, såsom reklamfilmer eller företagspresentationer, till kontinuerlig skapande och hantering av innehåll för sociala medier.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Hur fungerar er arbetsprocess, från koncept till färdig produkt?</Accordion.Header>
                            <Accordion.Body className="body-text">
                                <p>Vi strävar efter att göra hela processen så enkel och smidig som möjligt. Vi inleder med ett uppstartsmöte där vi diskuterar era behov och mål. Om vi är en lämplig match, skapar vi en plan och bokar inspelningsdag. Efter inspelning redigerar vi tills ni är nöjda. Om överenskommet, publicerar vi även materialet på era sociala medier.
                                </p>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Vilka sociala medieplatformar skapar ni material till?</Accordion.Header>
                            <Accordion.Body className="body-text">
                                <p>Instagram, Facebook, tik tok och YouTube.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Vilka typer av företag eller organisationer är era typiska kunder?</Accordion.Header>
                            <Accordion.Body className="body-text">
                                <p>Små till mellanstora företag, webshoppar, lokala serviceföretag, coacher/föreläsare/utbildare, personliga varumärken
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Hur lång tid tar det att producera en reklamfilm eller innehåll till sociala medier?</Accordion.Header>
                            <Accordion.Body className="body-text">
                                <p>Själva produktionen varierar beroende på budget och överenskommelse, men generellt kan kortare reklam- eller presentationsfilmer färdigställas på 2-4 dagar.
                                    Innehållsskapande för sociala medier krävs vanligtvis 8 timmars arbete för att producera material för en hel månads publicering.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Hur hanterar ni ändringar eller omarbetningar i projekt?</Accordion.Header>
                            <Accordion.Body className="body-text">
                                <p>Vi är flexibla och samarbetsvilliga när det kommer till ändringar och omarbetningar. Vårt mål är att säkerställa att ni är fullständigt nöjda med resultatet, och vi arbetar tillsammans med er för att uppnå detta.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>
        </>
    )
}

export default FAQ;