import { Button, Col, Container, Fade, Image, Row } from "react-bootstrap";
import FAQ from "../component/FAQ";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const About = () => {
    const settings1 = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0px",
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
        cssEase: 'linear'
    };
    const settings2 = {
        dots: false,
        infinite: true, 
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false, 
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow:5,
                    slidesToScroll: 1,
                  
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  
                }
            },
        ],
    };

    return (
        <div className="pt-md-105 pt-30">
            {/* <Container>
                <Button variant="link" className="btn-back p-0 mb-9 d-md-none" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
                            <path d="M1.22504 5.45014L0.799295 5.0244L0.373554 5.45014L0.799295 5.87588L1.22504 5.45014ZM15.6752 6.05223C16.0077 6.05223 16.2773 5.78266 16.2773 5.45014C16.2773 5.11761 16.0077 4.84805 15.6752 4.84805V6.05223ZM5.61601 0.207681L0.799295 5.0244L1.65078 5.87588L6.46749 1.05916L5.61601 0.207681ZM0.799295 5.87588L5.61601 10.6926L6.46749 9.84111L1.65078 5.0244L0.799295 5.87588ZM1.22504 6.05223H15.6752V4.84805H1.22504V6.05223Z" fill="#DCE1DE"/>
                        </svg>
                    </Button>
            </Container> */}
            <section className="pb-md-150 pb-100">
                <Container>
                    <Row className="g-20">
                        <Col lg={5}>
                            <div className="">
                                <h1 className="h1-text mb-10px">Hej, det är vi som är <span className="gradient-text">Filmlabbet </span> 👋</h1>
                                <span className="subtitle-text-2 mb-10px d-block">Vår passion är att skapa högkvalitativt innehåll</span>
                                <div className="body-text">
                                    <p className="mb-md-30 mb-20">Varje företag är unikt och vi gör därför allt vi kan för att lyssna in vad just ni behöver. Varje projekt skräddarsys för att återspegla er företagsidentitet och era mål, det gäller såväl engångsproduktioner som kontinuerliga produktioner till sociala medier.</p>
                                    <p className="mb-md-30 mb-20">Vi är lyhörda, kreativa och framför allt, flexibla. Vi finns med under hela processen, från planering till inspelning och redigering. Ni bestämmer själv hur delaktiga ni vill vara - men vi har märkt att ett nära samarbete brukar ge de allra bästa resultaten.</p>
                                    <p className="mb-lg-30 mb-0">Tveka inte att höra av er till oss så berättar vi mer om hur vi kan hjälpa er! </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <Row className="g-10px g-lg-20">
                                <Col xs={12} className="">
                                    <div className="about-mainimg">
                                        <Image src="./assets/images/Frame981.webp" alt="Image1" />
                                    </div>
                                </Col>
                                <Col xs={3} className="">
                                    <div className="about-subimg">
                                        <Image src="./assets/images/image12.png" alt="Image1" />
                                    </div>
                                </Col>
                                <Col xs={3} className="">
                                    <div className="about-subimg">
                                        <Image src="./assets/images/IVS04361r.webp" alt="Image1" />
                                    </div>
                                </Col>
                                <Col xs={3} className="">
                                    <div className="about-subimg">
                                        <Image src="./assets/images/image12.png" alt="Image1" />
                                    </div>
                                </Col>
                                <Col xs={3} className="">
                                    <div className="about-subimg">
                                        <Image src="./assets/images/IVS04361r.webp" alt="Image1" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="pb-md-147 pb-100">
                <FAQ />
            </section>
            <section className="Booky-slider ">
                <Container>
                    <div className="Booky-slider-itembox">
                        <Slider {...settings1}>
                            <div className="Booky-slider-item">
                                <div className="Booky-slider-itemwrap">
                                    <span className="Booky-slider-logo">
                                        <img src="/assets/images/unsolved_logo.png" alt="logo" />
                                    </span>
                                    <div className="text-center mb-20">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="Booky-slider-text body-text mb-20">
                                        Filmlabbet är kreativa, prestigelösa och jobbar hårt! Dom fattar direkt vad man är ute efter och prickar alltid rätt! Världsklass!
                                    </p>
                                    <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                        <img width={73} height={73} src="/assets/images/Adam_Vikström.png" alt="Office"
                                            className="rounded-3" />
                                        <div>
                                            <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                                                Adam Vikström
                                            </span>
                                            <span className="Booky-slider-profileinfo-text2 d-block">
                                                Grundare
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Booky-slider-item">
                                <div className="Booky-slider-itemwrap">
                                    <span className="Booky-slider-logo">
                                        <img src="/assets/images/bagliora_logo.png" alt="logo" />
                                    </span>
                                    <div className="text-center mb-20">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="Booky-slider-text body-text mb-20">
                                        En väldigt smidig, enkelt samarbete och setup där allting sköttes proffsigt med både förberedelse, inspelning och leverans av material. De var också lyhörda och inkännande i vad vi ville få fram för känsla på materialet.
                                    </p>
                                    <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                        <img width={73} height={73} src="/assets/images/Julia_Fors.png" alt="Office"
                                            className="rounded-3" />
                                        <div>
                                            <span className="d-block mb-1 body-text mb-8px">
                                                Julia Fors
                                            </span>
                                            <span className="body-text d-block">
                                                Grundare
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Booky-slider-item">
                                <div className="Booky-slider-itemwrap">
                                    <span className="Booky-slider-logo">
                                        <img src="/assets/images/booky_logo.png" alt="logo" />
                                    </span>
                                    <div className="text-center mb-20">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="Booky-slider-text body-text mb-20">
                                        Vi har använt oss av Filmlabbet flera gånger - både till enskilda projekt men även till återkommande film och redigeringsprojekt. Dom är snabba, otroligt proffsiga och förstår alltid 100 % vilken känsla vi vill åt, Och på toppen av det, dom är otroliga roliga att jobba med. Rekommenderar varmt!
                                    </p>
                                    <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                        <img width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                                            className="rounded-3" />
                                        <div>
                                            <span className="d-block mb-1 body-text mb-8px">
                                                Charlotte Dunhoff
                                            </span>
                                            <span className="body-text d-block">
                                                Grundare & VD
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </Container>
                <div className="Booky-slider-logowrap mb-md-150 mb-100 mt-10px">
                    <Slider {...settings2}>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/booky.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/estrella.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/knowly.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/yttrerbygg.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/proglogis.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/walljoy.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/booky.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/estrella.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/knowly.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/yttrerbygg.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/proglogis.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/walljoy.png" alt="Spotify" />
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="pb-md-150 pb-100 text-center">
                <Container>
                    <h2 className="h1-text mb-md-6px mb-10px">Är ni redo att sätta igång?</h2>
                    <p className="body-text mb-10px mb-lg-20">Vi hjälper er att identifiera och skapa det innehåll som saknas för att driva ytterligare tillväxt.</p>
                    <a href="https://filmlabbet.scoreapp.com/" target="_blank" className="btn btn-primary">Läs mer</a> 
                </Container>
            </section>
        </div>
    )
}

export default About;