import { useState, useEffect } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import FAQ from "../component/FAQ";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs } from '../store/api/course.api'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";


const Contact = () => {

    const [value, setValue] = useState({ email: "" });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let require = []
    const onChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        // Scroll the HTML and body elements to the top
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }, []);
    const oncheckboxchange = (e) => {
        if (e.target.checked && !require.includes(e.target.value)) {
            require.push(e.target.value)
        } else {
            let index = require.indexOf(e.target.value); // 1
            console.log(index)
            require.splice(index, 1)

        }
        console.log(require)
    }
    const onAdd = () => {
        let data = {
            email: value.email,
            phone: value?.phone,
            message: value?.message,
            name: value?.name,
            business_name: value?.business_name,
            require_service: require.toString(),
            from_studio: false
        }
        dispatch(contactUs(data))
            .unwrap()
            .then((res) => {
                console.log(res.status)
                if (res.status === 200) {
                    navigate('/thank-you');
                }

            })
            .catch(() => {

            });
    };
    const openCal = () => {
        window.open('https://cal.com/filmlabbet', '_blank')
    }
    const settings1 = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0px",
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
        cssEase: 'linear'
    };
    const settings2 = {
        dots: false,
        infinite: true, 
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false, 
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow:5,
                    slidesToScroll: 1,
                  
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  
                }
            },
        ],
    };
    return (
        <>
            <div className="pt-30 pb-100 pt-lg-106 pb-lg-150">
                <Container>
                    <div className="contact-blockwrap position-relative mx-auto w-100">
                        <div className="mb-20 mb-lg-40 text-center">
                            <h2 className="h1-text mn-6px">Har du en fråga?</h2>
                            <p className="body-text mb-0">Använd formuläret för att komma i kontakt med oss. </p>
                        </div>
                        <Form>
                            <Row className="gy-10px gx-20">
                                <Col sm={6}>
                                    <Form.Group controlId="inputName">
                                        <Form.Label className="body-text">* Namn</Form.Label>
                                        <Form.Control type="text" placeholder="Johan Johansson" name='name' value={value.name} onChange={onChange} className="m-0" />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label className="body-text">* Företag</Form.Label>
                                        <Form.Control type="text" placeholder="Företaget AB" name='business_name' value={value.business_name} onChange={onChange} className="m-0" />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="inputTel">
                                        <Form.Label className="body-text">* Telefon</Form.Label>
                                        <Form.Control type="tel" placeholder="+46 (0)768 35 75 12" name='phone' value={value.phone} onChange={onChange} className="m-0" />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="inputPost">
                                        <Form.Label className="body-text">* E-post</Form.Label>
                                        <Form.Control type="email" placeholder="Johan@gmail.com" name='email' value={value.email} onChange={onChange} className="m-0" />
                                    </Form.Group>
                                </Col>
                                <Col lg={12}>
                                    <Form.Group>
                                        <Form.Label className="body-text">Meddelande</Form.Label>
                                        <Form.Control
                                            className="contact-teaxtarea m-0"
                                            as="textarea"
                                            placeholder="Hur kan vi hjälpa dig?"
                                            name="message"
                                            value={value.message} onChange={onChange}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col sm={12} className="text-center mt-10px mt-lg-20">
                                    <Button variant="primary" type="button" onClick={onAdd} className="m-0">
                                        Skicka
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Container>
            </div>

            <section className="pb-lg-147 pb-100">
                <FAQ />
            </section>
            
            <section className="Booky-slider ">
                <Container>
                    <div className="Booky-slider-itembox">
                        <Slider {...settings1}>
                            <div className="Booky-slider-item">
                                <div className="Booky-slider-itemwrap">
                                    <span className="Booky-slider-logo">
                                        <img src="/assets/images/unsolved_logo.png" alt="logo" />
                                    </span>
                                    <div className="text-center mb-20">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="Booky-slider-text body-text mb-20">
                                        Filmlabbet är kreativa, prestigelösa och jobbar hårt! Dom fattar direkt vad man är ute efter och prickar alltid rätt! Världsklass!
                                    </p>
                                    <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                        <img width={73} height={73} src="/assets/images/Adam_Vikström.png" alt="Office"
                                            className="rounded-3" />
                                        <div>
                                            <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                                                Adam Vikström
                                            </span>
                                            <span className="Booky-slider-profileinfo-text2 d-block">
                                                Grundare
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Booky-slider-item">
                                <div className="Booky-slider-itemwrap">
                                    <span className="Booky-slider-logo">
                                        <img src="/assets/images/bagliora_logo.png" alt="logo" />
                                    </span>
                                    <div className="text-center mb-20">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="Booky-slider-text body-text mb-20">
                                        En väldigt smidig, enkelt samarbete och setup där allting sköttes proffsigt med både förberedelse, inspelning och leverans av material. De var också lyhörda och inkännande i vad vi ville få fram för känsla på materialet.
                                    </p>
                                    <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                        <img width={73} height={73} src="/assets/images/Julia_Fors.png" alt="Office"
                                            className="rounded-3" />
                                        <div>
                                            <span className="d-block mb-1 body-text mb-8px">
                                                Julia Fors
                                            </span>
                                            <span className="body-text d-block">
                                                Grundare
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Booky-slider-item">
                                <div className="Booky-slider-itemwrap">
                                    <span className="Booky-slider-logo">
                                        <img src="/assets/images/booky_logo.png" alt="logo" />
                                    </span>
                                    <div className="text-center mb-20">
                                        <span>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.79797 2.96617C5.20519 1.95096 5.40881 1.44336 5.73961 1.373C5.82659 1.35451 5.91649 1.35451 6.00347 1.373C6.33427 1.44336 6.53788 1.95096 6.94511 2.96617C7.17669 3.54349 7.29248 3.83216 7.50914 4.0285C7.56991 4.08357 7.63588 4.13261 7.70612 4.17495C7.95654 4.32588 8.26914 4.35388 8.89436 4.40987C9.95274 4.50466 10.4819 4.55205 10.6435 4.85378C10.677 4.91627 10.6998 4.98393 10.7109 5.05395C10.7644 5.39201 10.3754 5.74595 9.59733 6.45383L9.38126 6.6504C9.0175 6.98135 8.83562 7.14682 8.73042 7.35333C8.66731 7.47721 8.625 7.61062 8.60517 7.74822C8.57212 7.97761 8.62538 8.21766 8.7319 8.69777L8.76996 8.8693C8.96099 9.73029 9.05651 10.1608 8.93727 10.3724C8.83017 10.5625 8.6329 10.6841 8.41498 10.6946C8.17237 10.7061 7.83053 10.4276 7.14684 9.87049C6.6964 9.50345 6.47119 9.31992 6.22117 9.24823C5.99268 9.18272 5.75039 9.18272 5.52191 9.24823C5.27189 9.31992 5.04667 9.50345 4.59623 9.87049C3.91255 10.4276 3.57071 10.7061 3.3281 10.6946C3.11018 10.6841 2.9129 10.5625 2.8058 10.3724C2.68657 10.1608 2.78208 9.73029 2.97311 8.86931L3.01117 8.69777C3.11769 8.21766 3.17095 7.97761 3.1379 7.74822C3.11807 7.61062 3.07576 7.47721 3.01265 7.35333C2.90745 7.14682 2.72557 6.98135 2.36181 6.6504L2.14575 6.45383C1.36768 5.74595 0.978644 5.39201 1.03222 5.05395C1.04332 4.98393 1.06607 4.91627 1.09954 4.85378C1.26114 4.55205 1.79033 4.50466 2.84871 4.40987C3.47393 4.35388 3.78654 4.32588 4.03696 4.17495C4.1072 4.13261 4.17317 4.08357 4.23394 4.0285C4.4506 3.83216 4.56639 3.54349 4.79797 2.96617Z" fill="white" stroke="white" stroke-width="1.26846" />
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="Booky-slider-text body-text mb-20">
                                        Vi har använt oss av Filmlabbet flera gånger - både till enskilda projekt men även till återkommande film och redigeringsprojekt. Dom är snabba, otroligt proffsiga och förstår alltid 100 % vilken känsla vi vill åt, Och på toppen av det, dom är otroliga roliga att jobba med. Rekommenderar varmt!
                                    </p>
                                    <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                        <img width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                                            className="rounded-3" />
                                        <div>
                                            <span className="d-block mb-1 body-text mb-8px">
                                                Charlotte Dunhoff
                                            </span>
                                            <span className="body-text d-block">
                                                Grundare & VD
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </Container>
                <div className="Booky-slider-logowrap mb-md-150 mb-100 mt-10px">
                    <Slider {...settings2}>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/booky.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/estrella.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/knowly.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/yttrerbygg.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/proglogis.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/walljoy.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/booky.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/estrella.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/knowly.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/yttrerbygg.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/proglogis.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/walljoy.png" alt="Spotify" />
                        </div>
                    </Slider>
                </div>
            </section>

            <section className="pb-md-150 pb-100 text-center">
                <Container>
                    <h2 className="h1-text mb-md-6px mb-10px">Är ni redo att sätta igång?</h2>
                    <p className="body-text mb-10px mb-lg-20">Vi hjälper er att identifiera och skapa det innehåll som saknas för att driva ytterligare tillväxt.</p>
                    <a href="https://filmlabbet.scoreapp.com/" target="_blank" className="btn btn-primary">Läs mer</a> 
                </Container>
            </section>
        </>

    )
}

export default Contact;