import { Button, Container, Image, Row, Col } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { singleActivity } from '../store/api/course.api';
import SeeTrailer from '../component/SeeTrailer';
import { isMobile } from 'react-device-detect';

const Individual = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [single, setSingleProject] = useState();
    const { id, categoty_id } = useParams();
    const [show, setShow] = useState(false);
    const [limit, setLimit] = useState(isMobile ? 5 : 100);
    const OpenContact = () => {
        navigate('/kontakt');
    }
    useEffect(() => {
        refresh();
    }, []);
    const refresh = () => {
        let data = {
            'categoty_id': categoty_id,
            'id': id,
        }
        dispatch(singleActivity(data)).then((single) => {
            setSingleProject(single.payload?.data);
        });
    }
    const handleClose = () => {
        setShow(false);

    }
    const OpenDiloag = () => {
        setShow(true);
    }
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
            {/* <div className="individual-bg-section"><Image src={single?.image}></Image></div>
             */}
            <div
                className="individual-bg-section"
                style={{ backgroundImage: `url(${single?.image})` }}
            ></div>
            <div className="individual-page-banner">
                <section className="p-0">
                    <Container>
                        <div className="text-center d-flex flex-column justify-content-center">
                            <div className="maxw-918 mx-auto">
                                <span className="body-text d-block mb-6px">{single?.sub_title}</span>
                                <h1 className="h1-text mb-20"> {single?.title}</h1>
                                {single?.category_id != 20 &&
                                    <Button variant="primary" onClick={() => OpenDiloag()}>Se film</Button>
                                }
                                {single?.category_id == 20 &&
                                    <Button variant="primary" onClick={() => scrollToSection('Se_foton')}>Se foton</Button>
                                }
                            </div>
                        </div>
                        <div className="individual-page-banner-bottom-text max-744 mx-auto text-center body-text text-white" dangerouslySetInnerHTML={{ __html: single?.about_producer }}></div>

                    </Container>
                </section>
            </div>
            <section className={`individual-grid-wrap pt-100 pt-lg-150`}>
                <Container>
                    <p className={`text-white text-center mb-0 body-text font-clash-grotesk ${single && single.media.length > 0 ? '' : ''}`} dangerouslySetInnerHTML={{ __html: single?.about_film }}></p>
                    {/* {single && single.media.length > 0 &&
                        <div className="individual-grids">
                            {
                                single && single.media.length > 0 && single.media.map((mediai, index) => (
                                    <div class="ind-grid-item item1">
                                        <Image className="rounded-3" src={mediai?.media_image}></Image>
                                    </div>
                                ))
                            }
                        </div>
                    } */}

                    {single && single.media.length > 0 &&
                        <div className="pt-100 pt-lg-150" id="Se_foton">
                            <div className="individual-img-grid-wrap" >
                                {
                                    single && single.media.length > 0 && single.media.slice(0, limit).map((mediai, index) => (
                                        <div class="img-grid-box">
                                            <Image className="rounded-3" src={mediai?.media_image}></Image>
                                        </div>
                                    ))
                                }
                            </div>
                            {single.media.length > 5 && limit != single.media.length + 1 &&
                                <div className="text-center d-md-none mt-20">
                                    <Button variant="white" onClick={() => setLimit(single.media.length + 1)}>Visa mer bilder</Button>
                                </div>
                            }
                        </div>
                    }

                </Container>
                {/* <Container>
                    <div className="pt-100 pt-lg-250">
                        <div className="individual-img-grid-wrap">
                            <div className="img-grid-box">4x5</div>
                            <div className="img-grid-box">9x16</div>
                            <div className="img-grid-box">16x9</div>
                            <div className="img-grid-box">9x16</div>
                            <div className="img-grid-box">4x5</div>
                            <div className="img-grid-box">4x5</div>
                            <div className="img-grid-box">16x9</div>
                        </div>
                        <div className="text-center d-md-none mt-20">
                            <Button variant="white">Visa mer bilder</Button>
                        </div>
                    </div>
                </Container> */}
            </section>
            <section className="pt-100 pt-lg-150 pb-150 text-center z-1 position-relative">
                <Container>
                    <h2 className="h1-text mb-10px mb-lg-6px">Är ni redo att sätta igång?</h2>
                    <p className="body-text mb-10px mb-lg-20">Vi hjälper er att identifiera och skapa det innehåll som saknas för att driva ytterligare tillväxt.</p>
                    <a href="https://filmlabbet.scoreapp.com/" target="_blank" className="btn btn-primary">Läs mer</a>
                </Container>
            </section>
            {show &&
                <SeeTrailer show={show} src={single?.video_url} handleClose={handleClose} />
            }
        </>
    )
}

export default Individual;