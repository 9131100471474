import { useEffect,useState } from "react";
import { Button, Container, Image, Row, Col,Dropdown } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';
import { getProjectList } from '../store/api/course.api';
import { useDispatch } from 'react-redux';
import ComonDropDown from "../component/DropDownComon";
import { useParams } from 'react-router-dom';
const Portfolio = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [allCources, setAllCources] = useState();
    localStorage.setItem("catName","Narrative");
    const OpenPage = (id, name) => {
        console.log(name)
        if(name === 'Havsöga') {
            navigate('/havsoga');
        } else {
            navigate('/individual/portfolio/'+id);
        }
        
    }
   
    useEffect(() => {
        refresh();
    }, [id]);
    const refresh = () => {
        let data = {
          'id': id? id:17 ,
        }
          dispatch(getProjectList(data)).then((course) => {
              setAllCources(course.payload?.data);
          });
      }
    return (
        <div className="pt-41 pt-md-112 pb-109 pb-md-150">
            <section className="studio banner-wrapper mb-60 mb-md-133">
                <Container>
                    <div className="text-center d-flex flex-column justify-content-center">
                        <div className="maxw-918 mx-auto">
                            <span className="body-text mb-6px d-block">Utvalda projekt</span>
                            <h1 className="h1-text mb-20">Utforska</h1>
                            <ComonDropDown/>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="studio-grids">
                <Container fluid>
                    <Row className="g-20">
                    { allCources && allCources.length > 0 && allCources?.map((item, index) => (
                        <Col sm={12} key={item.id} onClick={()=>OpenPage(item.id,item?.title)}>
                            <div className="flex-img-content-box">
                                <div className="flex-img-boxwrap">
                                    <Image src={item?.image}></Image>
                                </div>
                                <div className="flex-img-details d-flex flex-column flex-lg-row  gap-20 gap-lg-46">
                                    <div className="flex-grow-1">
                                        <h2 className="subtitle-text-2 mb-6px mb-md-4px">{item?.title}</h2>
                                        <p className="body-text mb-10px">{item?.sub_title}</p>
                                        <div className="flex-img-details-paragraph">
                                            <p className="body-text mb-0" dangerouslySetInnerHTML={{ __html: item?.about_film  }}></p>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 align-self-lg-end">
                                        <Button variant="primary" className="w-100">Se mer</Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                         ))
                        }
                    </Row>
                </Container>
            </section>

        </div>
    )
}

export default Portfolio;