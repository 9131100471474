import { Button, Container, Image, Row, Col } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';

const Home = () => {

    const navigate = useNavigate();

    const OpenContact = () => {
        navigate('/kontakt');
    }
    const OpenPortfolio = () => {
        navigate('/portfolio');
    }
   
    return (
        <section className="banner-wrapper pt-213 pb-308 pt-lg-248 pb-lg-347">
        <Container>
            <div className="text-center d-flex flex-column justify-content-center">
                <div className="mb-5">
                    <h1 className="text-white h1-text mb-4px mb-md-6px">Contentbyrån för e-handlare.</h1>
                    <span className="body-text">Vi hjälper e-handlare att skala upp med mer och bättre content.</span>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-20">
                    <a href="https://filmlabbet.scoreapp.com/" target="_blank" className="btn btn-primary">Läs mer</a> 
                    <Button onClick={OpenPortfolio} variant="white">Projekt</Button>
                </div>
            </div>
        </Container>
    </section>
    )
}

export default Home;