import { Col, Container, Image, Row } from "react-bootstrap";
import FAQ from "../component/FAQ";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import GridBlock from "../component/GridBlock";

const ThankYou = (toggle = true) => {
    return (
        <div >
            <div className="pt-213 pb-400 pt-lg-248 pb-lg-396">
                <Container>
                        <Row className="align-items-center">
                            <Col>
                                <div className="text-center">
                                    <h1 className="h1-text mb-6px">Tack för ditt meddelande!</h1>
                                    <span className="body-text mb-20 d-block">Vi kommer att kontakta dig inom 24 timmar.</span>
                                    <Link to="/" className="btn btn-primary">Tillbaka till startsidan</Link>
                                </div>
                            </Col>
                            {/* <Col md={6}>
                                <div className="text-md-left text-center">
                                    <Image src="/assets/images/thanks.gif" alt="" />
                                </div>
                            </Col> */}
                        </Row>
                </Container>
            </div>
            {/* <section className="text-center pb-md-15 pb-11">
                <Container>
                    <div className="d-flex justify-content-between flex-wrap gap-8">
                    <div className="logo-wrap">
                            <Image src="/assets/images/logos/factoringgruppen.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/Docens_logo.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/ytterbygg_logo.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/logos/booky_logo.png" alt="Spotify" />
                        </div>
                    </div>
                </Container>
            </section> */}
            {/* <GridBlock title="Spana in vår portfolio" /> */}
            {/* <FAQ /> */}
        </div>
    )
}

export default ThankYou;